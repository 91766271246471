import { FC, useEffect, useState, useMemo } from 'react';
import { FiX, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import { capitalize, isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/pt';

import { Modal } from '@/components';
import { TIndicator } from '@/typings/api';

import { requestGroupIndicators } from '@/services/inspection';
import useInspectionContext from '@/contexts/InspectionContext';

import { formatValueByIndicator } from './functions';
import * as S from './styles';

interface IndicatorsModalProps {
	pillarIndicator: string;
	onClose: () => void;
}

const IndicatorsModal: FC<IndicatorsModalProps> = ({ pillarIndicator, onClose }) => {

	const { selectedUnit } = useInspectionContext();

	const [ indicator, setIndicator ] = useState<TIndicator>({} as TIndicator);
	const [ isLoadingIndicators, setIsLoadingIndicators ] = useState<boolean>(true);
	const [ currentIndicatorMonthIndex, setCurrentIndicatorMonthIndex ] = useState<number>(0);

	useEffect(() => {
		const getIndicators = async () => {
			try {
				setIsLoadingIndicators(true);
				const indicatorsData = await requestGroupIndicators(pillarIndicator, selectedUnit.idUnidade);

				if (indicatorsData.meses.length === 0) throw Error('Unidade sem indicadores!');

				indicatorsData.meses.reverse();
				setIndicator(indicatorsData);
			} catch (error: any) {
				const { message } = error.response?.data || error;
				toast.error(message || 'Não foi possível buscar os indicadores do pilar!');
				onClose();
			} finally {
				setIsLoadingIndicators(false);
			}
		}

		getIndicators();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectedMonth = useMemo(() => {
		if (isEmpty(indicator)) return null;
		return indicator.meses[currentIndicatorMonthIndex];
	}, [indicator, currentIndicatorMonthIndex]);

	const formattedDate = useMemo(() => {
		if (!selectedMonth) return '';
		return moment(`${selectedMonth.ano}/${selectedMonth.mes}/01`).locale('pt').format('MMMM YYYY');
	}, [selectedMonth]);

	if (isLoadingIndicators) {
		return (
			<Modal
				display={{
					direction: 'column',
					items: 'center',
					content: 'flex-end'
				}}
			>
				<S.Container>
					<header className="modal__header">
						<Skeleton width={ 170 } height={ 20 } borderRadius={ 6 } />

						<button className="modal__button" onClick={ onClose }>
							<FiX />
						</button>
					</header>

					<section className="group">
						<Skeleton width={ 150 } height={ 20 } borderRadius={ 6 } />

						<ul className="group__list">
							<li className="group__item">
								<Skeleton width={ 175 } height={ 20 } borderRadius={ 6 } />
							</li>

							<li className="group__item">
								<Skeleton width={ 200 } height={ 20 } borderRadius={ 6 } />
							</li>

							<li className="group__item">
								<Skeleton width={ 120 } height={ 20 } borderRadius={ 6 } />
							</li>
						</ul>
					</section>
				</S.Container>
			</Modal>
		);
	}

	return (
		<Modal
			display={{
				direction: 'column',
				items: 'center',
				content: 'flex-end'
			}}
		>
			<S.Container>
				<header className="modal__header">
					<h2 className="modal__title">
						{ indicator.pilar }
					</h2>

					<button className="modal__button" onClick={ onClose }>
						<FiX />
					</button>
				</header>

				{
					!!selectedMonth && (
						<section className="group">
							<div className="group__header">
								<button
									className="group__button"
									disabled={ currentIndicatorMonthIndex === 0 }
									onClick={ () => setCurrentIndicatorMonthIndex(currentIndex => currentIndex - 1) }
								>
									<FiChevronLeft />
								</button>

								<h3 className="group__title">
									{ capitalize(formattedDate) }
								</h3>

								<button
									className="group__button"
									disabled={ currentIndicatorMonthIndex === (indicator.meses.length - 1) }
									onClick={ () => setCurrentIndicatorMonthIndex(currentIndex => currentIndex + 1) }
								>
									<FiChevronRight />
								</button>
							</div>

							<ul className="group__list">
								{
									selectedMonth.indicadores.map((indicator, index) => (
										<li key={ index } className="group__item">
											<span className="group__key">
												{ indicator.descricao }
											</span>

											<span className="group__value">
												{ formatValueByIndicator(indicator) }
											</span>
										</li>
									))
								}
							</ul>
						</section>
					)
				}
			</S.Container>
		</Modal>
	);

}

export default IndicatorsModal;
