import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import reportWebVitals from './reportWebVitals';

import Routes from '@/routes';
import { AppProvider } from '@/contexts/AppContext';
import { GlobalStyles } from '@/styles/globals';

import '@/i18n';

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
	<Router>
		<AppProvider>
			<GlobalStyles />

			<ToastContainer
				theme="colored"
				position="top-center"
				style={{ textAlign: 'center' }}
			/>

			<Routes />
		</AppProvider>
	</Router>
);

reportWebVitals();
