import { TQuestionAnswer } from '@/typings/feedback';
import { TWizardStep, TWizardStepButton } from '@/typings/wizard';

import { makeId } from '@/utils/functions';

const DEFAULT_STEP_BUTTONS: Array<TWizardStepButton> = [
	{
		id: makeId(),
		text: 'Anterior',
		type: 'PREVIOUS'
	},
	{
		id: makeId(),
		text: 'Próximo',
		type: 'NEXT'
	}
];

const DEFAULT_QUESTION_ANSWERS: Array<TQuestionAnswer> = [
	{
		id: makeId(),
		answer: 'Sim'
	},
	{
		id: makeId(),
		answer: 'Não'
	}
];

export const FEEDBACK_DATA: Array<TWizardStep> = [
	{
		id: makeId(),
		title: 'Iniciar feedback',
		type: 'SELECT_UNIT',
		buttons: [
			{
				id: makeId(),
				text: 'Iniciar',
				type: 'NEXT'
			}
		]
	},
	{
		id: makeId(),
		title: 'Seleção de avaliado',
		type: 'SELECT_COLLABORATOR',
		buttons: DEFAULT_STEP_BUTTONS
	},
	{
		id: makeId(),
		title: 'Resultados comerciais',
		type: 'FEEDBACK_QUESTION',
		buttons: DEFAULT_STEP_BUTTONS,
		feedbackQuestions: [
			{
				id: 'QUESTION_ID_1',
				question: 'A colaboradora realizou a gestão e distribuição das metas da clínica?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Por que?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_1',
					answer: 'Não'
				}
			},
			{
				id: 'QUESTION_ID_2',
				question: 'Propôs ações comerciais para alavancar o resultado da equipe? (Panfletagem, Parcerias e etc...)',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Qual a justificativa de não ter feito?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_2',
					answer: 'Não'
				}
			},
			{
				id: makeId(),
				question: 'Quais foram as ações realizadas pela colaboradora?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_2',
					answer: 'Sim'
				}
			},
			{
				id: makeId(),
				question: 'Como mediu o resultado e efetividade das ações?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_2',
					answer: 'Sim'
				}
			},
			{
				id: 'QUESTION_ID_3',
				question: 'Ela atingiu meta de dezenas?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Qual a justificativa de não ter atingido a meta?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_3',
					answer: 'Não'
				}
			},
			{
				id: makeId(),
				question: 'Quantas e quais?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_3',
					answer: 'Sim'
				}
			},
			{
				id: 'QUESTION_ID_4',
				question: 'Conseguiu atingir a meta da unidade?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Qual a justificativa de não ter atingido a meta?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_4',
					answer: 'Não'
				}
			},
			{
				id: makeId(),
				question: 'Quais meses de meta ela atingiu e quais valores? ',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_4',
					answer: 'Sim'
				}
			},
			{
				id: makeId(),
				question: 'Caso não tenha atingido a meta mensal, qual % de resultado foi entregue?',
				questionType: 'FIELD',
				answer: '',
			}
		]
	},
	{
		id: makeId(),
		title: 'Gestão administrativa',
		type: 'FEEDBACK_QUESTION',
		buttons: DEFAULT_STEP_BUTTONS,
		feedbackQuestions: [
			{
				id: makeId(),
				question: 'Acompanhou a organização da clínica ao que se refere manutenção,  organização e funcionamento da máquina?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Realizou as rotinas de informe de vendas, boleto do caixa e estoque da unidade (se preciso)?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Apresentou os objetivos do mês para a equipe?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			}
		]
	},
	{
		id: makeId(),
		title: 'Gestão de pessoas',
		type: 'FEEDBACK_QUESTION',
		buttons: DEFAULT_STEP_BUTTONS,
		feedbackQuestions: [
			{
				id: 'QUESTION_ID_1',
				question: 'Conversou com o time para conhecê-lo e aplicar feedback?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Por que?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_1',
					answer: 'Não'
				}
			},
			{
				id: makeId(),
				question: 'Caso ela tenha compartilhado sobre a conversa com o time, conte-nos como foi.',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_1',
					answer: 'Sim'
				}
			},
			{
				id: makeId(),
				question: 'Realizou aprovação e revisão do ponto da equipe e escala do mês?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			}
		]
	},
	{
		id: makeId(),
		title: 'Sistemas',
		type: 'FEEDBACK_QUESTION',
		buttons: DEFAULT_STEP_BUTTONS,
		feedbackQuestions: [
			{
				id: 'QUESTION_ID_1',
				question: 'Conseguiu realizar as principais Operações no Vectis (orçamento, pipeline de vendas, relatórios gerenciais, contrato, orçamentos não concretizados, desempenho das aplicadoras, mandar ativos, fazer ligações)?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Quais foram as dificuldades?',
				questionType: 'FIELD',
				answer: '',
				fatherQuestion: {
					id: 'QUESTION_ID_1',
					answer: 'Não'
				}
			},
			{
				id: makeId(),
				question: 'Conseguiu realizar as principais operações no BLIP (atendimento ao cliente, vendas, agendamento, solicitação de cliente)?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Quais foram as dificuldades?',
				questionType: 'FIELD',
				answer: '',
			}
		]
	},
	{
		id: makeId(),
		title: 'Observações finais',
		type: 'FEEDBACK_QUESTION',
		buttons: [
			{
				id: makeId(),
				text: 'Anterior',
				type: 'PREVIOUS'
			},
			{
				id: makeId(),
				text: 'Finalizar',
				type: 'NEXT'
			}
		],
		feedbackQuestions: [
			{
				id: makeId(),
				question: 'Como você avalia o desempenho dessa colaboradora?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: [
					{
						id: makeId(),
						answer: 'Bom'
					},
					{
						id: makeId(),
						answer: 'Mediano'
					},
					{
						id: makeId(),
						answer: 'Ruim'
					}
				]
			},
			{
				id: makeId(),
				question: 'Essa pessoa deve continuar na sua equipe?',
				questionType: 'SELECTION',
				answer: '',
				questionAnswers: DEFAULT_QUESTION_ANSWERS
			},
			{
				id: makeId(),
				question: 'Justifique.',
				questionType: 'FIELD',
				answer: '',
			}
		]
	}
];
