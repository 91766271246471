import styled, { css } from 'styled-components';

type TInput = {
	hasError?: boolean;
	isDisabled?: boolean;
}

export const Input = styled.div<TInput>`
	display: flex;
	flex-direction: column-reverse;
	row-gap: .5rem;

	.input__label {
		font-weight: 500;
		transition: .3s;

		${props => props.hasError && 'color: red;'};
		${props => props.isDisabled && `color: ${props.theme.colors.textLight}`};
	}

	.input__field {
		padding: 0 1.5rem;
		height: 52px;
		font-size: ${props => props.theme.font.normalSize};
		background-color: ${props => props.theme.colors.body};
		border: 1px solid ${props => props.hasError ? 'red' : props.theme.colors.body};
		transition: .3s;
		border-radius: .65rem;

		:focus {
			border-color: ${props => !props.hasError && props.theme.colors.primary};
		}

		${props => props.isDisabled && css`
			cursor: no-drop;
			color: ${props.theme.colors.textLight};
		`};
	}

	.input__field:focus + .input__label {
		color: ${props => !props.hasError && props.theme.colors.primary};
	}

	.input__error {
		color: red;
		font-size: ${props => props.theme.font.smallSize};
	}
`;
