import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { FiLogOut, FiCheckSquare, FiSmile, FiPieChart, FiChevronRight, FiClipboard } from 'react-icons/fi';

import useAppContext from '@/contexts/AppContext';
import { STICKY_NOTES_DATA } from '@/mocks/stickyNotesData';

import * as comingSoonAnimation from '@/assets/animations/coming-soon.json';
import * as S from './styles';

const { version: APP_VERSION } = require('../../../package.json');
const STICKY_NOTE_BG_COLOR = {
	'INSPECTION': 'note-green',
	'FEEDBACK': 'note-purple'
}

const Home: React.FC = () => {

	const { user, logout } = useAppContext();

	const isOddAndLast = (index: number) => {
		const arrayLength = STICKY_NOTES_DATA.length;

		const isPairNumber = arrayLength < 3 || arrayLength % 2 === 0;
		const isLastElement = arrayLength === (index + 1);

		return isPairNumber ? false : isLastElement;
	}

	return (
		<S.Home>
			<button className="logout" onClick={ logout }>
				<FiLogOut className="logout__icon" />
				Sair
			</button>

			<header className="header">
				<h1 className="header__title">
					Bem vinda { user.nome }!
				</h1>

				<h2 className="header__subtitle">
					Home
				</h2>
			</header>

			<nav className="nav">
				<Link to="/inspection" className="link">
					<div className="link__data">
						<div className="link__icon link__icon-green">
							<FiCheckSquare />
						</div>

						<strong className="link__label">
							Vistoria de unidade
						</strong>
					</div>

					<FiChevronRight className="link__arrow" />
				</Link>

				<Link to="/#" className="link link-disabled">
					<div className="link__data">
						<div className="link__icon link__icon-gray">
							<FiSmile />
						</div>

						<div className="link__subdata">
							<strong className="link__label">
								Feedback de colaboradora
							</strong>

							<small className="link__sublabel">
								(em breve)
							</small>
						</div>
					</div>

					<FiChevronRight className="link__arrow link__arrow-hidden" />
				</Link>

				<Link to="#" className="link link-disabled">
					<div className="link__data">
						<div className="link__icon link__icon-gray">
							<FiPieChart />
						</div>

						<div className="link__subdata">
							<strong className="link__label">
								Relatórios por unidade
							</strong>

							<small className="link__sublabel">
								(em breve)
							</small>
						</div>
					</div>

					<FiChevronRight className="link__arrow link__arrow-hidden" />
				</Link>

				<Link to="#" className="link link-disabled">
					<div className="link__data">
						<div className="link__icon link__icon-gray">
							<FiClipboard />
						</div>

						<div className="link__subdata">
							<strong className="link__label">
								Tarefas e planos de ação
							</strong>

							<small className="link__sublabel">
								(em breve)
							</small>
						</div>
					</div>

					<FiChevronRight className="link__arrow link__arrow-hidden" />
				</Link>
			</nav>

			<div className="divider" />

			<h2 className="notes-title">
				Seus lembretes
			</h2>

			<section className="notes">
				<Lottie
					width={ 200 }
					height={ 200 }
					isStopped={ false }
					isPaused={ false }
					options={{
						loop: true,
						autoplay: true, 
						animationData: comingSoonAnimation,
						rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
					}}
				/>

				<h3 className="notes__title">
					Em breve...
				</h3>
			</section>

			<footer className="footer">
				<small className="copyright">
					Vialaser Serviços Estéticos S.A
				</small>

				<small className="version">
					Versão: { APP_VERSION }
				</small>
			</footer>
		</S.Home>
	);
}

export default Home;

/*

Notes Structure

STICKY_NOTES_DATA.map((note, index) => (
	<div
		key={ note.id }
		className={`note ${isOddAndLast(index) && 'note-last'} ${STICKY_NOTE_BG_COLOR[note.type]}`}
	>
		<h2 className="note__title">
			{ note.title }
		</h2>

		<p className="note__description">
			{ note.description }
		</p>
	</div>
))

*/
