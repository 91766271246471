import React from 'react';
import * as S from './styles';

interface InspectionDraftModalProps {
	visible: boolean;
	onAccept: () => void;
	onClose: () => void;
}

const InspectionDraftModal: React.FC<InspectionDraftModalProps> = ({ visible, onAccept, onClose }) => {

	return (
		<S.InspectionDraftModal visible={ visible }>
			<div className="box">
				<div>
					<h1 className="box__title">
						Rascunho Encontrado!
					</h1>

					<p className="box__message">
						Deseja reutilizar uma vistoria já existente que não foi completada?
					</p>
				</div>

				<div className="box__buttons">
					<button className="box__button" onClick={ onClose }>
						Não
					</button>

					<button className="box__button" onClick={ onAccept }>
						Sim
					</button>					
				</div>				
			</div>
		</S.InspectionDraftModal>
	);

}

export default InspectionDraftModal;
