import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import useInspectionContext from '@/contexts/InspectionContext';
import { TInspectionSubgroupApiResponse } from '@/typings/api';

import * as S from './styles';

const ANSWER_COLOR_BY_INDEX: any = {
	0: 'grade-red',
	1: 'grade-yellow',
	2: 'grade-green'
}

interface InspectionSubgroupProps {
	subgroups: Array<TInspectionSubgroupApiResponse>;
}

const InspectionSubgroup: React.FC<InspectionSubgroupProps> = ({ subgroups }) => {

	const { selectSubgroupQuestionAnswer, toggleSubgroup } = useInspectionContext();

	return (
		<S.InspectionSubgroup>
			{
				subgroups.map((subgroup, subgroupIndex) => (
					<section
						key={ subgroup.idSubgrupo.toString() }
						className={`subgroup ${subgroup.subgrupoFechado && 'subgroup-closed'}`}
					>
						<div className="subgroup__header">
							<h3 className="subgroup__title">
								{ subgroup.descricaoSubgrupo }
							</h3>

							<button
								className="subgroup__grade"
								onClick={ () => toggleSubgroup(subgroupIndex, !subgroup.subgrupoFechado) }
							>
								Nota { subgroup.subgrupoFechado ? <FiChevronDown className="subgroup__grade-icon" /> : <FiChevronUp className="subgroup__grade-icon" /> }
							</button>
						</div>

						{
							subgroup.perguntas.map((question, questionIndex) => (
								<div key={ question.idPergunta.toString() } className="question">
									<p className="question__description">
										{ question.descricao }
									</p>

									<div className="question__grades">
										{
											question.respostas.map((answer, answerIndex) => (
												<button
													key={ answer.idOpcaoResposta.toString() }
													onClick={ () => selectSubgroupQuestionAnswer(subgroupIndex, questionIndex, answer.idOpcaoResposta) }
													className={`
														grade
														${ANSWER_COLOR_BY_INDEX[answerIndex]}
														${question.respostaSelecionada === answer.idOpcaoResposta && 'grade-selected'}
													`}
												>
													{ answer.escolha }
												</button>
											))
										}
									</div>
								</div>
							))
						}
					</section>
				))
			}
		</S.InspectionSubgroup>
	);

}

export default InspectionSubgroup;
