import React from 'react';
import Lottie from 'react-lottie';

import { useWindowBreakpoint } from '@/hooks';

import * as successAnimation from '@/assets/animations/success.json';
import * as S from './styles';

interface SuccessModalProps {
	visible: boolean;
	title: string;
	message: string;
	onCloseModal: () => void;
}

const ANIMATION_DIMENSIONS = {
	'xsm': { width: 265.33, height: 238 },
	'sm': { width: 265.33, height: 238 },
	'md': { width: 265.33, height: 238 },
	'lg': { width: 265.33, height: 238 },
	'xl': { width: 265.33, height: 238 },
	'xxl': { width: 265.33, height: 238 }
}

const SuccessModal: React.FC<SuccessModalProps> = ({ visible, title, message, onCloseModal }) => {

	const breakpoint = useWindowBreakpoint();

	return (
		<S.SuccessModal visible={ visible }>
			<div className="successbox">
				<div className="successbox__header">
					<Lottie
						width={ ANIMATION_DIMENSIONS[breakpoint].width }
						height={ ANIMATION_DIMENSIONS[breakpoint].height }
						isStopped={ false }
						isPaused={ false }
						options={{
							loop: true,
							autoplay: true, 
							animationData: successAnimation,
							rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
						}}
					/>

					<div>
						<h1 className="successbox__title">
							{ title }
						</h1>

						<p className="successbox__message">
							{ message }
						</p>
					</div>				
				</div>
				
				<button className="successbox__button" onClick={ onCloseModal }>
					Fechar
				</button>
			</div>
		</S.SuccessModal>
	);

}

export default SuccessModal;
