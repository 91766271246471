import styled from 'styled-components';
import { lighten } from 'polished';

export const Questions = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	.question {
		display: none;
		flex-direction: column;
		row-gap: .75rem;

		&-visible {
			display: flex;
		}

		&__title {
			font-size: ${props => props.theme.font.h3Size};
			font-weight: 600;
		}

		&__answers {
			display: flex;
			align-items: center;
			column-gap: 2rem;
		}

		&__field {
			height: 52px;
			padding: 1rem;
			color: ${props => props.theme.colors.text};
			border: 1px solid ${props => props.theme.colors.body};
			background-color: ${props => props.theme.colors.body};
			border-radius: 12px;
			transition: .3s;

			&:focus {
				border-color: ${props => props.theme.colors.primary};
			}
		}

		.answer {
			display: flex;
			align-items: center;
			column-gap: 1rem;
			background-color: transparent;

			&:focus .radio__indicator:not(.radio__indicator-selected) {
				background-color: ${props => lighten(.2, props.theme.colors.primary)};
			}
		}
	}
`;
