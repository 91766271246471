import styled from 'styled-components';
import { lighten } from 'polished';

export const Login = styled.div<{ isRememberMeChecked?: boolean; }>`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: ${props => props.theme.colors.secondary};

	.language-switch-container {
		position: fixed;
		top: 2rem;
		right: 2rem;
	}

	.lisa-image {
		align-self: center;
		width: 80%;
	}

	.login {
		height: 60%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: ${props => props.theme.colors.container};
		border-top-right-radius: 2rem;
		border-top-left-radius: 2rem;
		row-gap: 2rem;
		padding: 2rem;
		transition: .3s;

		&__title {
			margin-bottom: .25rem;
			font-size: ${props => props.theme.font.h1Size};
		}

		&__subtitle {
			color: ${props => props.theme.colors.textLight};
			font-size: ${props => props.theme.font.h3Size};
			font-weight: 500;
		}

		&__content {
			flex: 1;
			display: flex;
			flex-direction: column;
			row-gap: 1rem;

			.remember {
				display: flex;
				align-items: center;
				column-gap: .5rem;
				cursor: pointer;
				align-self: flex-start;
				background-color: transparent;

				&__checkbox {
					transition: .3s;
					border: 2px solid ${props => props.isRememberMeChecked ? props.theme.colors.primary : props.theme.colors.body};
					background-color: ${props => props.isRememberMeChecked ? props.theme.colors.primary : props.theme.colors.container};
					width: 18px;
					height: 18px;
					border-radius: .35rem;
					display: flex;
					align-items: center;
					justify-content: center;

					&-icon {
						font-size: 0.85rem;
						color: #fff;
					}
				}

				&__checkbox-checked {
					border-color: ${props => props.theme.colors.primary};
					background-color: ${props => props.theme.colors.primary};
				}

				&__label {
					cursor: pointer;
					font-weight: 500;
					font-size: ${props => props.theme.font.smallerSize};
				}
			}
		}

		&__button {
			width: 100%;
			height: 52px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: .75rem;
			font-weight: 500;
			background-color: ${props => props.theme.colors.primary};
			border-color: ${props => props.theme.colors.primary};
			color: #fff;
			transition: .3s;

			&:disabled {
				cursor: no-drop;
				background-color: ${props => lighten(.2, props.theme.colors.primary)};
				border-color: ${props => lighten(.2, props.theme.colors.primary)};
			}
		}
	}

	@media screen and (min-width: 600px) {
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		height: 100%;
		padding: 2rem 0;

		.lisa-image {
			width: 250px;
		}

		.login {
			width: 80%;
			border-radius: 2rem;
			height: auto;
		}
	}

	@media screen and (min-width: 850px) {
		.login {
			width: 60%;
		}
	}

	@media screen and (min-width: 1250px) {
		.login {
			width: 40%;
		}
	}

	@media screen and (min-width: 1700px) {
		.login {
			width: 30%;
		}
	}
`;
