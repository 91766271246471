import React from 'react';
import * as S from './styles';

type TCancelModalButton = {
	text: string;
	onClick: () => void;
}

interface CancelModalProps {
	visible: boolean;
	title: string;
	message: string;
	leftButton: TCancelModalButton;
	rightButton: TCancelModalButton;
}

const CancelModal: React.FC<CancelModalProps> = ({ visible, title, message, leftButton, rightButton }) => {
	return (
		<S.CancelModal visible={ visible }>
			<div className="box">
				<div>
					<h2 className="box__title">
						{ title }
					</h2>

					<p className="box__message">
						{ message }
					</p>
				</div>

				<div className="box__buttons">
					<button className="box__button box__button-default" onClick={ leftButton.onClick }>
						{ leftButton.text }
					</button>

					<button className="box__button box__button-primary" onClick={ rightButton.onClick }>
						{ rightButton.text }
					</button>
				</div>
			</div>
		</S.CancelModal>
	);
}

export default CancelModal;
