import { DefaultTheme } from 'styled-components';

export const createTheme = (darkMode: boolean = false): DefaultTheme => {
	return {
		darkMode,
		defaults: {
			headerHeight: '70px',
			containerMaxWidth: '1280px',
			mb05: '.5rem',      //8px
			mb075: '.75rem',    //12px
			mb1: '1rem',        //16px
			mb15: '1.5rem',     //24px
			mb2: '2rem',        //32px
			mb25: '2.5rem'      //40px
		},
		font: {
			family: 'Inter, sans-serif',
			h1Size: '1.5rem',       //24px
			h2Size: '1.25rem',      //20px
			h3Size: '1rem',         //16px
			biggestSize: '2rem',    //32px
			bigSize: '1.25rem',     //24px
			mediumSize: '1.125rem', //20px
			normalSize: '1rem',     //16px
			smallSize: '.875rem',   //14px
			smallerSize: '.75rem',  //12px
			iconSize: '1.25rem'
		},
		colors: {
			primary: darkMode ? '#04D361' : '#38BE46',
			secondary: '#7B51D2',
			text: darkMode ? '#FFFFFF' : '#141328',
			textLight: darkMode ? '#A2A1A4' : '#6e6d7a',
			body: darkMode ? '#121414' : '#F2F2F2',
			container: darkMode ? '#29292E' : '#FFFFFF',
			gray: darkMode ? '#A9A9A9' : '#DBDBDB',
			grayLight: darkMode ? '#303036' : '#ebebeb',
			red: '#d60e0e',
			yellow: '#eacc23'
		}
	};
}
