import { transparentize } from 'polished';
import styled from 'styled-components';

export const Summary = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	.summary {
		display: flex;
		flex-direction: column;
		row-gap: .75rem;

		&__data {
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			font-size: ${props => props.theme.font.h3Size};
		}

		&__average {
			font-size: ${props => props.theme.font.smallSize};
			color: ${props => props.theme.colors.textLight};
		}

		.item {
			display: flex;
			align-items: center;
			column-gap: 1rem;

			&__description {
				flex: 1;
			}

			&__average {
				font-weight: 700;

				&-red {
					color: ${props => props.theme.colors.red};
				}

				&-yellow {
					color: ${props => props.theme.colors.yellow};
				}

				&-green {
					color: ${props => props.theme.colors.primary};
				}
			}
		}

		&__legends {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 2rem;
		}
	}

	.chart {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 2rem 0;
	}

	.legend {
		display: flex;
		align-items: center;
		column-gap: .5rem;

		&__box {
			width: 20px;
			height: 20px;
			border-radius: 8px;
			border: 2px solid;

			&-purple {
				border-color: ${props => props.theme.colors.secondary};
				background-color: ${props => transparentize(.5, props.theme.colors.secondary)};
			}

			&-green {
				border-color: ${props => props.theme.colors.primary};
				background-color: ${props => transparentize(.5, props.theme.colors.primary)};
			}
		}

		&__label {
			color: ${props => props.theme.colors.textLight};
			font-size: ${props => props.theme.font.smallSize};
		}
	}
`;

export const LoadingContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 2rem;

	.error {
		display: flex;
		flex-direction: column;
		row-gap: .5rem;
		text-align: center;

		&__title {
			color: ${props => props.theme.colors.red};
			font-size: ${props => props.theme.font.h1Size};
		}

		&__message {
			width: 250px;
		}
	}
`;
