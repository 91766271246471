import { TWizardStep, TWizardStepButton } from '@/typings/wizard';
import { makeId } from '@/utils/functions';

export const DEFAULT_INSPECTION_STEP_BUTTONS: Array<TWizardStepButton> = [
	{
		id: makeId(),
		text: 'Anterior',
		type: 'PREVIOUS'
	},
	{
		id: makeId(),
		text: 'Próximo',
		type: 'NEXT'
	}
];

export const INSPECTION_SELECT_UNIT_STEP: TWizardStep = {
	id: makeId(),
	title: 'Seleção de unidade',
	type: 'SELECT_UNIT',
	buttons: [
		{
			id: makeId(),
			text: 'Iniciar',
			type: 'NEXT'
		}
	]
};

export const INSPECTION_SUMMARY_STEP: TWizardStep = {
	id: makeId(),
	title: 'Resumo geral',
	type: 'SUMMARY',
	buttons: DEFAULT_INSPECTION_STEP_BUTTONS,
};

export const INSPECTION_FINISH_STEP: TWizardStep = {
	id: makeId(),
	title: 'Revisão e finalização',
	type: 'FINISH',
	buttons: [
		{
			id: makeId(),
			text: 'Anterior',
			type: 'PREVIOUS'
		},
		{
			id: makeId(),
			text: 'Finalizar',
			type: 'NEXT'
		}
	],
};
