import styled from 'styled-components';

export const InspectionSubgroup = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	.subgroup {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		overflow: hidden;

		&-closed {
			height: 20px;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			font-size: ${props => props.theme.font.h3Size};
			font-weight: 600;
		}

		&__grade {
			display: flex;
			align-items: center;
			column-gap: .5rem;
			background-color: transparent;
			color: ${props => props.theme.colors.textLight};
			font-size: ${props => props.theme.font.smallSize};

			&-icon {
				font-size: ${props => props.theme.font.iconSize};
			}
		}

		.question {
			display: flex;
			align-items: flex-start;
			column-gap: .75rem;

			&__description {
				flex: 1;
			}

			&__grades {
				display: flex;
				align-items: center;
				column-gap: .5rem;

				.grade {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 25px;
					height: 25px;
					border-radius: 100%;
					background-color: transparent;
					color: ${props => props.theme.colors.text};
					border: 1px solid ${props => props.theme.colors.text};
					font-size: ${props => props.theme.font.smallerSize};
					font-weight: 600;
					transition: .3s;

					&-selected {
						color: #fff;
					}

					&-selected.grade-red {
						border-color: ${props => props.theme.colors.red};
						background-color: ${props => props.theme.colors.red};
					}

					&-selected.grade-yellow {
						border-color: ${props => props.theme.colors.yellow};
						background-color: ${props => props.theme.colors.yellow};
					}

					&-selected.grade-green {
						border-color: ${props => props.theme.colors.primary};
						background-color: ${props => props.theme.colors.primary};
					}
				}
			}
		}
	}

	@media screen and (min-width: 767px) {
		.group .item {
			column-gap: 1rem;
		}
	}
`;
