import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
	:root {
        --toastify-toast-width: 490px;
    }

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		font-family: ${props => props.theme.font.family};
	}

	html {
        scroll-behavior: smooth;

        @media(max-width: 475px) {
            font-size: 90%;
        }
    }

	body {
        color: ${props => props.theme.colors.text};
        background-color: ${props => props.theme.colors.body};
        transition: .4s;
    }

	body, input, button, textarea {
		outline: none;
        color: ${props => props.theme.colors.text};
		font-family: ${props => props.theme.font.family};
		font-size: ${props => props.theme.font.normalSize};
        -webkit-font-smoothing: antialiased;
        font-smooth: always;
	}

	a {
		text-decoration: none;
	}

	a, button {
		cursor: pointer;
	}

	button {
        border: none;
        outline: none;
		-webkit-tap-highlight-color: transparent;
    }

	h1, h2, h3 {
        font-weight: 600;
	}

	h1, h2, h3, h4, h5, p {
		margin: 0;
	}

	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-track {
		background: ${props => props.theme.colors.container};
		border-left: 1px solid ${props => props.theme.colors.body};
	}
	::-webkit-scrollbar-thumb {
		background: #888;
	}
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
