import styled, { css } from 'styled-components';

export const CancelModal = styled.div<{ visible: boolean; }>`
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: .3s;

	${props => props.visible && css`
		visibility: visible;
		opacity: 1;
	`};

	.box {
		display: flex;
		flex-direction: column;
		width: 80%;
		padding: 2rem;
		row-gap: 2rem;
		border-radius: 2rem;
		background-color: ${props => props.theme.colors.container};
		transition: .3s;

		&__title {
			font-size: ${props => props.theme.font.h2Size};
			margin-bottom: .25rem;
		}

		&__message {
			color: ${props => props.theme.colors.textLight};
		}

		&__buttons {
			display: flex;
			align-items: center;
			column-gap: 2rem;
		}

		&__button {
			height: 48px;
			width: 100%;
			border-radius: 1rem;
			font-weight: 500;

			&-default {
				background-color: ${props => props.theme.colors.body};
			}

			&-primary {
				background-color: ${props => props.theme.colors.red};
				color: #fff;
			}
		}
	}

	@media screen and (min-width: 767px) {
		.box {
			width: 400px;

			&__button {
				height: 52px;
			}
		}
	}
`;
