import React, { useState, useEffect, useContext, createContext } from 'react';
import { toast } from 'react-toastify';

import { isEmpty } from 'lodash';

import { TWizardStep } from '@/typings/wizard';
import { TInspectionGroupApiResponse, TUnit } from '@/typings/api';

import { requestUserUnits } from '@/services/user';
import { requestInspectionGroups } from '@/services/inspection';

import { LOCALSTORAGE_KEYS } from '@/utils/constants';
import {
	DEFAULT_INSPECTION_STEP_BUTTONS,
	INSPECTION_SELECT_UNIT_STEP,
	INSPECTION_SUMMARY_STEP,
	INSPECTION_FINISH_STEP
} from '@/mocks/inspection';

interface InspectionContextProps {
	units: Array<TUnit>;
	selectedUnit: TUnit;
	isUserInUnit: boolean;
	currentWizardStep: number;
	wizardSteps: Array<TWizardStep>;
	inspectionObservation: string;
	
	setIsUserInUnit: (isUserInUnit: boolean) => void;
	setInspectionObservation: (observation: string) => void;
	decreaseWizardStep: () => void;
	increaseWizardStep: () => void;

	getWizardSteps: () => Promise<void>;
	getUnits: () => Promise<void>;
	setSelectedUnit: (unit: TUnit) => void;
	makeUseOfInspectionDraft: () => void;

	selectSubgroupQuestionAnswer: (subgroupIndex: number, questionIndex: number, selectedAnswer: number) => void;
	toggleSubgroup: (subgroupIndex: number, toogleValue: boolean) => void;

	clearInspectionData: () => void;
}

const InspectionContext: React.Context<InspectionContextProps> = createContext({} as InspectionContextProps);

export const InspectionProvider: React.FC<{ children: any; }> = ({ children }) => {

	const [ units, setUnits ] = useState<Array<TUnit>>([]);
	const [ selectedUnit, setSelectedUnit ] = useState<TUnit>({} as TUnit);

	const [ isUserInUnit, setIsUserInUnit ] = useState<boolean>(false);
	const [ currentWizardStep, setCurrentWizardStep ] = useState<number>(0);
	const [ inspectionObservation, setInspectionObservation ] = useState<string>('');
	
	const [ wizardSteps, setWizardSteps ] = useState<Array<TWizardStep>>([]);

	const decreaseWizardStep = () => {
		setCurrentWizardStep(step => step === 0 ? 0 : step - 1);
	}

	const increaseWizardStep = () => {
		setCurrentWizardStep(step => {
			const isLastStep = step + 1 === wizardSteps.length;
			return isLastStep ? step : step + 1;
		});
	}

	const getWizardSteps = async () => {
		try {
			const inspectionGroupsForWizard: Array<TWizardStep> = [INSPECTION_SELECT_UNIT_STEP];
			const inspectionGroups = await requestInspectionGroups();

			inspectionGroups.forEach((group: TInspectionGroupApiResponse) => {
				inspectionGroupsForWizard.push({
					id: group.idGrupo,
					pillarIndicator: group.pilarNegocioIndicador,
					title: group.descricaoGrupo,
					type: 'INSPECTION_GROUP',
					buttons: DEFAULT_INSPECTION_STEP_BUTTONS,
					inspectionSubgroups: group.subgrupos,
					hasIndicator: !!group.pilarNegocioIndicador
				});
			});

			inspectionGroupsForWizard.push(INSPECTION_SUMMARY_STEP, INSPECTION_FINISH_STEP);
			setWizardSteps(inspectionGroupsForWizard);
		} catch (e) {
			const error: any = e;
			const defaultErrorMessage = 'Não foi possível buscas as perguntas da vistoria. Tente novamente!';
			
			if (!error.response) toast.error(defaultErrorMessage);
			toast.error(error.response.data.message);
		}
	}

	const getUnits = async () => {
		try {
			const units = await requestUserUnits();
			setUnits(units);
			if (units.length === 1) setSelectedUnit(units[0]);
		} catch (e) {
			const error: any = e;
			const defaultErrorMessage = 'Não foi possível buscas as unidade para a vistoria. Tente novamente!';
			
			if (!error.response) toast.error(defaultErrorMessage);
			toast.error(error.response.data.message);
		}	
	}

	const makeUseOfInspectionDraft = () => {
		const inspectionDraft = localStorage.getItem(LOCALSTORAGE_KEYS.INSPECTION);
		if (!inspectionDraft) return;

		const inspection = JSON.parse(inspectionDraft);
		setSelectedUnit(inspection.selectedUnit);
		setWizardSteps(inspection.wizardSteps);
		
		localStorage.removeItem(LOCALSTORAGE_KEYS.INSPECTION);
	}

	const selectSubgroupQuestionAnswer = (subgroupIndex: number, questionIndex: number, selectedAnswer: number) => {
		setWizardSteps(previousStep => {
			const newSteps = [ ...previousStep ];
	
			if (!!newSteps[currentWizardStep].inspectionSubgroups) { // @ts-ignore
				newSteps[currentWizardStep].inspectionSubgroups[subgroupIndex].perguntas[questionIndex].respostaSelecionada = selectedAnswer;
			}
	
			return newSteps;
		});
	}
	
	const toggleSubgroup = (subgroupIndex: number, toogleValue: boolean) => {
		setWizardSteps(previousStep => {
			const newSteps = [ ...previousStep ];
	
			if (!!newSteps[currentWizardStep].inspectionSubgroups) { // @ts-ignore
				newSteps[currentWizardStep].inspectionSubgroups[subgroupIndex].subgrupoFechado = toogleValue;
			}
	
			return newSteps;
		});
	}

	const clearInspectionData = () => {
		setSelectedUnit({} as TUnit);
		setCurrentWizardStep(0);
		setWizardSteps([]);
		setUnits([]);
	}

	useEffect(() => {
		const onConfirmRefresh = (event: BeforeUnloadEvent) => {
			event.preventDefault();

			if (!isEmpty(selectedUnit)) {
				localStorage.setItem(LOCALSTORAGE_KEYS.INSPECTION, JSON.stringify({
					selectedUnit,
					wizardSteps
				}));
			}
			
			return event.returnValue = 'Tem certeza que deseja sair?';
		}

		window.addEventListener('beforeunload', onConfirmRefresh);
		return () => window.removeEventListener('beforeunload', onConfirmRefresh);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUnit, wizardSteps]);

	return (
		<InspectionContext.Provider
			value={{
				units,
				selectedUnit,
				isUserInUnit,
				currentWizardStep,
				wizardSteps,
				inspectionObservation,

				setIsUserInUnit,
				setInspectionObservation,
				decreaseWizardStep,
				increaseWizardStep,

				getWizardSteps,
				getUnits,
				setSelectedUnit,
				makeUseOfInspectionDraft,

				selectSubgroupQuestionAnswer,
				toggleSubgroup,

				clearInspectionData
			}}
		>
			{ children }
		</InspectionContext.Provider>
	);

}

const useInspectionContext = () => useContext(InspectionContext);
export default useInspectionContext;
