import React from 'react';
import * as S from './styles';

const SelectUnit: React.FC = () => {

	return (
		<S.SelectUnit>
			<h3 className="title">
				Em qual unidade você está?
			</h3>

			<div className="units">
				<button className="unit">
					<div className="unit__data">
						<p className="unit__name">
							Farol Shopping
						</p>

						<small className="unit__location">
							Tubarão - SC
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator" />
						</div>
					</div>
				</button>

				<button className="unit">
					<div className="unit__data">
						<p className="unit__name">
							Naçoes Shopping
						</p>

						<small className="unit__location">
							Criciuma - SC
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator radio__indicator-selected" />
						</div>
					</div>
				</button>

				<button className="unit">
					<div className="unit__data">
						<p className="unit__name">
							Floripa Shopping
						</p>

						<small className="unit__location">
							Florianópolis - SC
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator" />
						</div>
					</div>
				</button>
			</div>
		</S.SelectUnit>
	);

}

export default SelectUnit;
