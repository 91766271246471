import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { ThemeProvider, DefaultTheme } from 'styled-components';

import { api } from '@/services/api';
import { TUserData } from '@/typings/api';

import { useRedirection } from '@/hooks';

import { LOCALSTORAGE_KEYS } from '@/utils/constants';

import { createTheme } from '@/styles/theme';

interface AppContextProps {
	user: TUserData;
	theme: DefaultTheme;
	isAuthenticated: boolean;

	saveUserData: (userData: TUserData, token: string) => void;
	logout: () => void;
}

const AppContext: React.Context<AppContextProps> = createContext({} as AppContextProps);

const AUTH_HEADER = 'x-user-auth'

export const AppProvider: React.FC<{ children: any; }> = ({ children }) => {

	const navigate = useNavigate();
	const { redirectionPath } = useRedirection({ fallback: '/' });

	const [ user, setUser ] = useState<TUserData>({} as TUserData);
	const [ theme ] = useState<DefaultTheme>(createTheme());

	const saveUserData = (userData: TUserData, token: string) => {
		api.defaults.headers.common[AUTH_HEADER] = token;

		localStorage.setItem(LOCALSTORAGE_KEYS.USER, JSON.stringify(userData));
		localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, token);

		setUser(userData);
	}

	const logout = () => {
		delete api.defaults.headers.common[AUTH_HEADER];

		localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
		localStorage.removeItem(LOCALSTORAGE_KEYS.USER);

		setUser({} as TUserData);
	}

	useEffect(() => {
		const authenticateToken = () => {
			const token = localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN);
			const userData = localStorage.getItem(LOCALSTORAGE_KEYS.USER);

			if (!token || !userData) {
				localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
				localStorage.removeItem(LOCALSTORAGE_KEYS.USER);

				return;
			}

			saveUserData(JSON.parse(userData), token);
			navigate('/');
		}

		authenticateToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (redirectionPath && !isEmpty(user)) {
			navigate(redirectionPath);
		}
	}, [redirectionPath]);

	return (
		<AppContext.Provider
			value={{
				user,
				theme,
				isAuthenticated: !isEmpty(user),
				
				saveUserData,
				logout
			}}
		>
			<ThemeProvider theme={ theme }>
				{ children }
			</ThemeProvider>
		</AppContext.Provider>
	);

}

const useAppContext = () => useContext(AppContext);
export default useAppContext;
