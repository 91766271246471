import styled, { css } from 'styled-components';

export const SuccessModal = styled.div<{ visible: boolean; }>`
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: .3s;

	${props => props.visible && css`
		visibility: visible;
		opacity: 1;
	`};

	.successbox {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		padding: 3rem;
		row-gap: 3rem;
		background: rgba(255, 255, 255, 0.192);
		backdrop-filter: blur(10px);

		&__header {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			row-gap: 2.75rem;
		}

		&__title {
			font-size: ${props => props.theme.font.h1Size};
			margin-bottom: .25rem;
		}

		&__message {
			color: ${props => props.theme.colors.textLight};
		}

		&__button {
			height: 52px;
			font-weight: 500;
			border-radius: .75rem;
			background-color: ${props => props.theme.colors.primary};
			border: 1px solid ${props => props.theme.colors.primary};
			color: #fff;
		}
	}

	@media screen and (min-width: 767px) {
		.successbox {
			position: relative;

			&__button {
				align-self: center;
				width: 500px;
				position: absolute;
				bottom: 15%;
			}
		}
	}

`;
