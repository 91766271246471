import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import pt from './translations/pt.json';
import es from './translations/es.json';

i18n.use(initReactI18next).init({
	lng: 'pt',
	fallbackLng: 'pt',
	debug: false,
	resources: {
		pt: { translation: pt },
		es: { translation: es }
	}
});

export default i18n;
