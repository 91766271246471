import React, { useState, useContext, createContext } from 'react';

import { FEEDBACK_DATA } from '@/mocks/feedbackData';

import { TWizardStep } from '@/typings/wizard';
import { TUnit } from '@/typings/api';

interface FeedbackContextProps {
	selectedUnit: TUnit;
	currentWizardStep: number;
	wizardSteps: Array<TWizardStep>;

	decreaseWizardStep: () => void;
	increaseWizardStep: () => void;

	getWizardSteps: () => void;
	selectUnit: (unit: TUnit) => void;
	changeQuestionAnswer: (questionIndex: number, answer: string) => void;

	cancelFeedback: () => void;
}

const FeedbackContext: React.Context<FeedbackContextProps> = createContext({} as FeedbackContextProps);

export const FeedbackProvider: React.FC<{ children: any; }> = ({ children }) => {

	const [ selectedUnit, setSelectedUnit ] = useState<TUnit>({} as TUnit);
	const [ currentWizardStep, setCurrentWizardStep ] = useState<number>(0);
	
	const [ wizardSteps, setWizardSteps ] = useState<Array<TWizardStep>>([]);

	const decreaseWizardStep = () => {
		setCurrentWizardStep(step => step === 0 ? 0 : step - 1);
	}

	const increaseWizardStep = () => {
		setCurrentWizardStep(step => {
			const isLastStep = step + 1 === wizardSteps.length;
			return isLastStep ? step : step + 1;
		});
	}

	const getWizardSteps = async () => {
		try {
			setWizardSteps(FEEDBACK_DATA);				
		} catch (error) {
			console.log('Error: ', error);
		}
	}

	const selectUnit = (unit: TUnit) => {
		setSelectedUnit(unit);
	}

	const changeQuestionAnswer = (questionIndex: number, answer: string) => {
		setWizardSteps(currentSteps => {
			const newSteps = [ ...currentSteps ]; // @ts-ignore
			newSteps[currentWizardStep].feedbackQuestions[questionIndex].answer = answer;
			return newSteps;
		});
	}

	const cancelFeedback = () => {
		setSelectedUnit({} as TUnit);
		setCurrentWizardStep(0);
		setWizardSteps([]);
	}

	return (
		<FeedbackContext.Provider
			value={{
				selectedUnit,
				currentWizardStep,
				wizardSteps,

				decreaseWizardStep,
				increaseWizardStep,

				getWizardSteps,
				selectUnit,
				changeQuestionAnswer,

				cancelFeedback
			}}
		>
			{ children }
		</FeedbackContext.Provider>
	);

}

const useFeedbackContext = () => useContext(FeedbackContext);
export default useFeedbackContext;
