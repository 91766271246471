import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { TWizardStepButtonType } from '@/typings/wizard';

type TFeedback = {
	withTwoButtons: boolean;
	progressBarPercentage: string;
}

export const Feedback = styled.div<TFeedback>`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	height: 100vh;
	background-color: ${props => props.theme.colors.secondary};

	.header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 2rem 2rem 0 2rem;

		&__title {
			color: #fff;
			margin-bottom: .25rem;
			font-size: ${props => props.theme.font.h1Size};
		}

		&__subtitle {
			color: ${props => props.theme.colors.body};
			font-size: ${props => props.theme.font.h3Size};
			font-weight: 500;
		}

		&__button {
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: .5rem;
			color: #fff;
			font-size: ${props => props.theme.font.iconSize};
			background-color: rgba(255, 255, 255, 0.3);
		}
	}

	.progress {
		position: relative;
		margin: 0 2rem;

		&__bar {
			height: 6px;
			width: 100%;
			overflow: hidden;
			border-radius: 1rem;
			background-color: rgba(255, 255, 255, 0.3);

			&-indicator {
				width: ${props => props.progressBarPercentage};
				height: 100%;
				border-radius: 1rem;
				background-color: ${props => props.theme.colors.primary};
				transition: .3s;
			}
		}

		.indicator {
			margin-top: .5rem;
			display: flex;
			justify-content: flex-end;
			width: calc(${props => props.progressBarPercentage} + 17px);
			transition: .3s;

			&__box {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			&__triangle {
				width: 10px;
				height: 10px;
				background-color: ${props => props.theme.colors.primary};
				transform: rotate(45deg);
				margin-bottom: -7px;
			}

			&__percentage {
				width: 2.5rem;
				height: 1.75rem;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: ${props => props.theme.colors.primary};
				z-index: 2;

				> span {
					line-height: auto;
					font-weight: 500;
					color: #fff;
					font-size: ${props => props.theme.font.smallerSize};
				}
			}
		}
	}

	.main {
		flex: 1;
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
		background-color: ${props => props.theme.colors.container};
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 108px;

		.radio {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			background-color: ${props => props.theme.colors.primary};

			&__middle {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 16px;
				height: 16px;
				border-radius: 100%;
				background-color: ${props => props.theme.colors.container};
			}

			&__indicator {
				width: 12px;
				height: 12px;
				border-radius: 100%;
				background-color: ${props => props.theme.colors.container};
				transition: .3s;

				&-selected {
					background-color: ${props => props.theme.colors.primary};
				}
			}
		}
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		row-gap: 2rem;

		&__title {
			font-size: ${props => props.theme.font.h2Size};
		}
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: ${props => props.withTwoButtons ? 'space-between' : 'flex-end'};
		border-top: 1px solid ${props => props.theme.colors.body};
		background-color: ${props => props.theme.colors.container};
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 5;
		padding: 2rem;
		transition: .3s;
	}

	@media screen and (min-width: 767px) {
		min-height: 100vh;
		height: auto;

		.header {
			width: 650px;
			align-self: center;
		}
		
		.progress {
			width: 650px;
			align-self: center;
		}

		.main {
			width: 650px;
			align-self: center;
			border-bottom-left-radius: 2rem;
			border-bottom-right-radius: 2rem;
			margin-bottom: 2rem;
			padding-bottom: 0;
			overflow: hidden;
		}

		.footer {
			position: relative;
		}
	}
`;

export const StepAction = styled.button<{ btnType: TWizardStepButtonType; }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	width: 116px;
	border-radius: .75rem;
	font-weight: 500;
	transition: .3s;

	${props => props.btnType === 'PREVIOUS' && css`
		color: ${props.theme.colors.text};
		background-color: ${props.theme.colors.body};
		border-color: ${props.theme.colors.body};
	`};

	${props => props.btnType === 'NEXT' && css`
		color: #fff;
		background-color: ${props.theme.colors.primary};
		border-color: ${props.theme.colors.primary};
	`};

	&:disabled {
		${props => props.btnType === 'NEXT' && css`
			color: #fff;
			background-color: ${lighten(.2, props.theme.colors.primary)};
			border-color: ${lighten(.2, props.theme.colors.primary)};
			cursor: no-drop;
		`};
	}

	@media screen and (min-width: 767px) {
		width: 132px;
	}
`;
