export const STATUS_CODE = {
	OK: 200,
	CREATED_SUCCESSFULLY: 201,
	ACCEPTED: 202,
	NO_CONTENT: 204,
	MOVED_PERMANENTLY: 301,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	NOT_FOUND: 404,
	INTERNAL_SERVER_ERROR: 500
}

export const LOCALSTORAGE_KEYS = {
	TOKEN: '@VIALASER:token',
	USER: '@VIALASER:user',
	DEFAULT_LOGIN_USERNAME: '@VIALASER:default-username',
	INSPECTION: '@VIALASER:inspection-draft'
}
