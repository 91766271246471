import React, { useState, useEffect } from 'react';
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis
} from 'recharts';

import useAppContext from '@/contexts/AppContext';
import useInspectionContext from '@/contexts/InspectionContext';

import { LoadingIndicator } from '@/components';
import { TChartData } from '@/typings/api';

import { makeId } from '@/utils/functions';
import { useWindowBreakpoint } from '@/hooks';
import { requestInspectionChartData, requestLastInspectionChartData } from '@/services/inspection';

import { createAnswersArrayForApi } from '../../functions';
import * as S from './styles';

interface ILocalChartData extends TChartData {
	ultimaVistoria?: number;
}

const CHART_DIMENSIONS = {
	'xsm': { width: 300, height: 200 },
	'sm': { width: 300, height: 200 },
	'md': { width: 600, height: 300 },
	'lg': { width: 600, height: 300 },
	'xl': { width: 600, height: 300 },
	'xxl': { width: 600, height: 300 }
}

const Summary: React.FC = () => {

	const breakpoint = useWindowBreakpoint();

	const { theme } = useAppContext();
	const { wizardSteps, isUserInUnit, selectedUnit } = useInspectionContext();

	const [ processes, setProcesses ] = useState<Array<ILocalChartData>>([]);
	const [ general, setGeneral ] = useState<Array<ILocalChartData>>([]);

	const [ isLoading, setIsLoading ] = useState<boolean>(false);

	const setPercentageColor = (percentage: number) => {
		if (percentage >= 0 && percentage < 33) return 'item__average-red';
		if (percentage >= 34 && percentage < 66) return 'item__average-yellow';
		
		return 'item__average-green';
	}

	useEffect(() => {
		const getChartData = async () => {
			setIsLoading(true);

			try {
				const answers = createAnswersArrayForApi(wizardSteps);
				
				const currentInspectionChartData = await requestInspectionChartData(selectedUnit.idUnidade, isUserInUnit, answers);
				const lastInspectionChartData = await requestLastInspectionChartData(selectedUnit.idUnidade);

				const newChartData = {
					geral: currentInspectionChartData.geral.map(item => {
						const foundedOfLast = lastInspectionChartData.geral.find(
							last => last.descricao === item.descricao
						);

						return {
							...item,
							ultimaVistoria: foundedOfLast?.valor
						}
					}),
					processos: currentInspectionChartData.processos.map(item => {
						const foundedOfLast = lastInspectionChartData.processos.find(
							last => last.descricao === item.descricao
						);

						return {
							...item,
							ultimaVistoria: foundedOfLast?.valor
						}
					})
				}

				setProcesses(newChartData.processos);
				setGeneral(newChartData.geral);
			} catch (error) {
				console.log('Não foi possível trazer as dados dos gráficos!');
			}
			
			setIsLoading(false);
		}

		getChartData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return (
			<S.LoadingContainer>
				<LoadingIndicator
					width={ 50 }
					height={ 50 }
					color={ theme.colors.primary }
				/> 

				<p>Carregando dados...</p>
			</S.LoadingContainer>
		)
	}

	if (processes.length === 0 || general.length === 0) {
		return (
			<S.LoadingContainer>
				<div className="error">
					<h1 className="error__title">
						Ops!
					</h1>

					<p className="error__message">
						Não foi possível trazer os gráficos e seus dados.
					</p>
				</div>
			</S.LoadingContainer>
		)
	}

	return (
		<S.Summary>
			<div className="summary">
				<div className="summary__header">
					<h3 className="summary__title">
						Pilares gerais
					</h3>

					<span className="summary__average">
						Última / Atual
					</span>
				</div>

				{
					general.length > 0 && general.map(geral => (
						<div key={ makeId() } className="item">
							<p className="item__description">
								{ geral.descricao }
							</p>

							{
								!!geral.ultimaVistoria && (
									<div className={`item__average ${setPercentageColor(geral.ultimaVistoria)}`}>
										{ geral.ultimaVistoria }%
									</div>
								)
							}

							<div className={`item__average ${setPercentageColor(geral.valor)}`}>
								{ geral.valor }%
							</div>
						</div>
					))
				}

				<div className="chart">
					<RadarChart 
						cx="50%"
						cy="50%"
						outerRadius="80%"
						width={ CHART_DIMENSIONS[breakpoint].width }
						height={ CHART_DIMENSIONS[breakpoint].height }
						data={ general }
					>
						<PolarGrid />
						<PolarAngleAxis dataKey="descricao" fontSize={ 12 } />
						<PolarRadiusAxis fontSize={ 12 } />
						
						<Radar
							dataKey="ultimaVistoria"
							stroke={ theme.colors.secondary }
							fill={ theme.colors.secondary }
							fillOpacity={ 0.3 }
							strokeWidth={ 2 }
						/>

						<Radar
							dataKey="valor"
							stroke={ theme.colors.primary }
							fill={ theme.colors.primary }
							fillOpacity={ 0.3 }
							strokeWidth={ 2 }
						/>
					</RadarChart>
				</div>

				<div className="summary__legends">
					<div className="legend">
						<div className="legend__box legend__box-purple" />

						<small className="legend__label">
							Última Vistoria
						</small>
					</div>

					<div className="legend">
						<div className="legend__box legend__box-green" />

						<small className="legend__label">
							Vistoria Atual
						</small>
					</div>
				</div>
			</div>
			
			<div className="summary">
				<div className="summary__header">
					<h3 className="summary__title">
						Pilares de processos
					</h3>

					<span className="summary__average">
						Última / Atual
					</span>
				</div>

				{
					processes.length > 0 && processes.map(process => (
						<div key={ makeId() } className="item">
							<p className="item__description">
								{ process.descricao }
							</p>

							{
								!!process.ultimaVistoria && (
									<div className={`item__average ${setPercentageColor(process.ultimaVistoria)}`}>
										{ process.ultimaVistoria }%
									</div>
								)
							}

							<div className={`item__average ${setPercentageColor(process.valor)}`}>
								{ process.valor }%
							</div>
						</div>
					))
				}

				<div className="chart">
					<RadarChart 
						cx="50%"
						cy="50%"
						outerRadius="80%"
						width={ CHART_DIMENSIONS[breakpoint].width }
						height={ CHART_DIMENSIONS[breakpoint].height }
						data={ processes }
					>
						<PolarGrid />
						<PolarAngleAxis dataKey="descricao" fontSize={ 12 } />
						<PolarRadiusAxis fontSize={ 12 } />
						
						<Radar
							dataKey="ultimaVistoria"
							stroke={ theme.colors.secondary }
							fill={ theme.colors.secondary }
							fillOpacity={ 0.3 }
							strokeWidth={ 2 }
						/>

						<Radar
							dataKey="valor"
							stroke={ theme.colors.primary }
							fill={ theme.colors.primary }
							fillOpacity={ 0.3 }
							strokeWidth={ 2 }
						/>
					</RadarChart>
				</div>

				<div className="summary__legends">
					<div className="legend">
						<div className="legend__box legend__box-purple" />

						<small className="legend__label">
							Última Vistoria
						</small>
					</div>

					<div className="legend">
						<div className="legend__box legend__box-green" />

						<small className="legend__label">
							Vistoria Atual
						</small>
					</div>
				</div>
			</div>
		</S.Summary>
	);

}

export default Summary;
