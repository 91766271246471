import React from 'react';

import useInspectionContext from '@/contexts/InspectionContext';
import * as S from './styles';

const SelectUnit: React.FC = () => {

	const { units, selectedUnit, setSelectedUnit } = useInspectionContext();

	return (
		<S.SelectUnit>
			<h3 className="title">
				Em qual unidade você está?
			</h3>

			<div className="units">
				{
					units.map(unit => (
						<button
							key={ unit.idUnidade.toString() }
							className="unit"
							onClick={ () => setSelectedUnit(unit) }
						>
							<div className="unit__data">
								<p className="unit__name">
									{ unit.descricao }
								</p>
		
								<small className="unit__location">
									{ unit.cidade } - { unit.uf }
								</small>
							</div>
		
							<div className="radio">
								<div className={ selectedUnit.idUnidade === unit.idUnidade ? 'radio-selected' : '' } />
							</div>
						</button>
					))
				}
			</div>
		</S.SelectUnit>
	);

}

export default SelectUnit;
