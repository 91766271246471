const CURRENCY_INDICATOR = ['Faturado', 'Revenda'];
const PERCENTAGE_INDICATOR = [
	'Porcentagem Alcance',
	'Porcentagem Inadimplência',
	'% Alcance',
	'% Inadimplência',
	'Porcentagem presença',
	'Porcentagem qualidade de aplicação',
	'% presença' ,
	'% qualidade de aplicação'
];

export const formatValueByIndicator = (indicator: { descricao: string; valor: string; }) => {
	try {
		
		const value = Number(indicator.valor);
		if (isNaN(value)) return indicator.valor;

		const formatItem = (item: string) => item.toUpperCase().replaceAll(' ', '_');

		const isCurrency = CURRENCY_INDICATOR.map(item => formatItem(item)).includes(formatItem(indicator.descricao));
		const isPercentage = PERCENTAGE_INDICATOR.map(item => formatItem(item)).includes(formatItem(indicator.descricao));

		if (isCurrency) return value.toLocaleString('pt', {
			style: 'currency',
			currency: 'BRL'
		});

		if (isPercentage) return value + '%';

		return value;

	} catch (error) {
		return indicator.valor;
	}
}
