import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiX } from 'react-icons/fi';

import moment from 'moment';

import { SuccessModal, ErrorModal, CancelModal } from '@/components';
import { SelectUnit, SelectCollaborator, Questions } from './components';

import useFeedbackContext from '@/contexts/FeedbackContext';

import { TWizardStepButtonType, TWizardStepType } from '@/typings/wizard';
import { TFeedbackQuestion } from '@/typings/feedback';

import { calculateProgressBarPercentage } from '@/utils/functions';
import * as S from './styles';

const Feedback: React.FC = () => {

	const navigate = useNavigate();
	const {
		wizardSteps,
		currentWizardStep,
		getWizardSteps,
		increaseWizardStep,
		decreaseWizardStep,
		cancelFeedback
	} = useFeedbackContext();

	const [ whenInspectionStarted, setWhenInspectionStarted ] = useState<Date>();
	const [ isCancelModalVisible, setIsCancelModalVisible ] = useState<boolean>(false);
	const [ isSuccessModalVisible, setIsSuccessModalVisible ] = useState<boolean>(false);

	const handleFinishFeedback = () => {
		const questions = wizardSteps.filter(step => step.feedbackQuestions);
		console.log('Finish the feedback here!', whenInspectionStarted, questions);

		setIsSuccessModalVisible(true);
	}

	const handleCancelFeedback = () => {
		cancelFeedback();
		navigate('/');
	}

	const handleChangeCurrentStep = async (buttonType: TWizardStepButtonType) => {
		const isPreviousButton = buttonType === 'PREVIOUS';
		if (isPreviousButton) return decreaseWizardStep();

		const isFirstStep = currentWizardStep === 0;
		const isLastStep = currentWizardStep + 1 === wizardSteps.length;

		if (isFirstStep) setWhenInspectionStarted(moment().toDate());

		if (isLastStep) {
			handleFinishFeedback();
		} else {
			increaseWizardStep();
		}			
	}

	const checkAllVisibleQuestionsAnswers = () => {
		const questions = step.feedbackQuestions!;

		return questions.map(question => {
			if (question.fatherQuestion) {
				const { fatherQuestion } = question;
				const foundedFatherQuestion = questions.find(question => question.id === fatherQuestion.id);

				if (foundedFatherQuestion?.answer === fatherQuestion.answer) {
					return question;
				}
			} else {
				return question;
			}
		})
		.filter(question => question !== undefined)
		.some(question => !question?.answer);
	}

	const isButtonDisabled = (buttonType: TWizardStepButtonType) => {
		if (buttonType === 'PREVIOUS') return false;

		if (step.type === 'FEEDBACK_QUESTION') {
			const isDisabled = checkAllVisibleQuestionsAnswers();
			return isDisabled;
		}
	}

	useEffect(() => {
		getWizardSteps();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (wizardSteps.length === 0) {
		return <>Nada encontrado!</>
	}

	const step = wizardSteps[currentWizardStep];

	return (
		<S.Feedback
			withTwoButtons={ step.buttons.length > 1 }
			progressBarPercentage={ calculateProgressBarPercentage(wizardSteps.length, currentWizardStep) }
		>
			<header className="header">
				<div>
					<h1 className="header__title">
						Feedback
					</h1>

					<h2 className="header__subtitle">
						Passo { currentWizardStep + 1 } de { wizardSteps.length }
					</h2>
				</div>

				<button className="header__button" onClick={ () => setIsCancelModalVisible(true) }>
					<FiX />
				</button>
			</header>

			<section className="progress">
				<div className="progress__bar">
					<div className="progress__bar-indicator" />
				</div>

				<div className="indicator">
					<div className="indicator__box">
						<div className="indicator__triangle" />

						<div className="indicator__percentage">
							<span>
								{ calculateProgressBarPercentage(wizardSteps.length, currentWizardStep) }
							</span>
						</div>
					</div>
				</div>
			</section>

			<main className="main">
				<div className="content">
					<h2 className="content__title">
						{ step.title }
					</h2>

					<FeedbackContent
						type={ step.type }
						questions={ step.feedbackQuestions }
					/>
				</div>

				<footer className="footer">
					{
						step.buttons.map(button => (
							<S.StepAction
								key={ button.id }
								btnType={ button.type }
								disabled={ isButtonDisabled(button.type) }
								onClick={ () => handleChangeCurrentStep(button.type) }
							>
								{ button.text }
							</S.StepAction>
						))
					}
				</footer>
			</main>

			<SuccessModal
				visible={ isSuccessModalVisible }
				title={ 'Sucesso!' }
				message={ 'O feedback foi realizado com sucesso.' }
				onCloseModal={ () => setIsSuccessModalVisible(false) }
			/>

			<ErrorModal
				visible={ false }
				title={ 'Error!' }
				message={ 'Não foi possível realizar o feedback.' }
				onCloseModal={ () => setIsSuccessModalVisible(false) }
			/>

			<CancelModal
				visible={ isCancelModalVisible }
				title={ 'Cancelar feedback!' }
				message={ 'Tem certeza que quer cancelar o feedback?' }
				leftButton={{
					text: 'Não',
					onClick: () => setIsCancelModalVisible(false)
				}}
				rightButton={{
					text: 'Sim',
					onClick: handleCancelFeedback
				}}
			/>
		</S.Feedback>
	);
	
}

interface FeedbackContentProps {
	type: TWizardStepType;
	questions?: Array<TFeedbackQuestion>
}

const FeedbackContent: React.FC<FeedbackContentProps> = ({ type, questions }) => {

	if (type === 'SELECT_UNIT') return <SelectUnit />;
	if (type === 'SELECT_COLLABORATOR') return <SelectCollaborator />;
	if (type === 'FEEDBACK_QUESTION') return <Questions questions={ questions || [] } />

	return <>Nada!</>

}

export default Feedback;
