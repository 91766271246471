import { useState, useEffect } from 'react';
import { useQuery } from '@/hooks';

const AVAIABLE_PATHS = ['inspection', 'feedback'];

const useRedirection = ({ fallback = '' }: { fallback?: string } = {}) => {

	const query = useQuery();
	const [ redirectionPath, setRedirectionPath ] = useState<string>(fallback);

	useEffect(() => {
		const path = query.get('redirectTo');
		if (path && AVAIABLE_PATHS.includes(path)) setRedirectionPath('/' + path);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { redirectionPath };

}

export default useRedirection;
