import styled from 'styled-components';

export const Finish = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	.textarea {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		row-gap: .5rem;

		&__label {
			font-weight: 500;
			transition: .3s;

			> small {
				color: ${props => props.theme.colors.textLight};
				font-size: ${props => props.theme.font.smallerSize};
			}
		}

		&__input {
			padding: .75rem;
			font-size: ${props => props.theme.font.normalSize};
			background-color: ${props => props.theme.colors.body};
			border: 1px solid ${props => props.theme.colors.body};
			transition: .3s;
			border-radius: .65rem;
			height: 180px;
			resize: none;

			:focus {
				border-color: ${props =>  props.theme.colors.primary};
			}
		}
	}
`;
