import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	max-height: 90%;
	padding: 2rem;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
	background-color: ${props => props.theme.colors.container};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	overflow-y: auto;
	transition: .3s;

	::-webkit-scrollbar {
		display: none;
	}

	.modal__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.modal__title {
		font-size: ${props => props.theme.font.h2Size};
	}

	.modal__button {
		width: 30px;
		height: 30px;
		border-radius: 8px;
		background-color: ${props => props.theme.colors.body};
		font-size: ${props => props.theme.font.iconSize};
		color: ${props => props.theme.colors.text};
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.group {
		display: flex;
		flex-direction: column;
		row-gap: 2rem;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__button {
			width: 30px;
			height: 30px;
			border-radius: 8px;
			color: ${props => props.theme.colors.primary};
			background-color: ${props => transparentize(.8, props.theme.colors.primary)};
			font-size: ${props => props.theme.font.iconSize};
			display: flex;
			align-items: center;
			justify-content: center;
			transition: .3s;

			&:disabled {
				color: #ccc;
				background-color: ${props => transparentize(.4, props.theme.colors.body)};
				cursor: no-drop;
			}

		}

		&__title {
			font-weight: 500;
			font-size: ${props => props.theme.font.h3Size};
			color: ${props => props.theme.colors.primary};
		}

		&__list {
			list-style: none;
			display: flex;
			flex-direction: column;
			row-gap: .75rem;
		}

		&__item {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__key {
			font-size: ${props => props.theme.font.normalSize};
		}

		&__value {
			color: ${props => props.theme.colors.textLight};
		}
	}

	.status {
		font-weight: 500;
		color: ${props => props.theme.colors.secondary};
	}

	@media screen and (min-width: 767px) {
		width: 650px;
	}
`;
