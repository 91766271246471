import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import * as notFoundAnimation from '@/assets/animations/not-found.json';
import * as S from './styles';

const NotFound: React.FC = () => {

	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate('/');
		}, 5000);

		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<S.NotFound>
			<Lottie
				width={ 320 }
				height={ 200 }
				isStopped={ false }
				isPaused={ false }
				options={{
					loop: true,
					autoplay: true, 
					animationData: notFoundAnimation,
					rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
				}}
			/>

			<div className="notfound">
				<h2 className="notfound__title">
					Ops, não tem nada aqui...
				</h2>

				<p className="notfound__message">
					Você será redirecionado para o ínicio daqui a pouco
				</p>
			</div>
		</S.NotFound>
	);

}

export default NotFound;
