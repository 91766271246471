import React from 'react';
import * as S from './styles';

interface InputProps {
	id: string;
	type?: string;
	label: string;
	value: string;
	error?: string;
	disabled?: boolean;
	onChangeValue: (text: string) => void;
}

const Input: React.FC<InputProps> = ({ id, type, label, value, error = '', disabled = false, onChangeValue }) => {
	return (
		<S.Input hasError={ !!error } isDisabled={ disabled }>
			{
				!!error && (
					<span className="input__error">{ error }</span>
				)
			}

			<input
				id={ id }
				type={ type }
				className="input__field"
				value={ value }
				disabled={ disabled }
				onChange={ event => onChangeValue(event.target.value) }
			/>

			<label htmlFor={ id } className="input__label">
				{ label }
			</label>
		</S.Input>
	);
}

export default Input;
