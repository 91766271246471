import { useState, useEffect } from 'react';

import { getWindowDimensions } from '@/utils/functions';

type TBreakpoint = 'xsm' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const useWindowBreakpoint = () => {
	const [ breakpoint, setBreakpoint ] = useState<TBreakpoint>('sm');

	useEffect(() => {
		const handleResize = () => {
			const { width } = getWindowDimensions();

			if (width < 576) setBreakpoint('xsm');
			if (width >= 576) setBreakpoint('sm');
			if (width >= 768) setBreakpoint('md');
			if (width >= 992) setBreakpoint('lg');
			if (width >= 1200) setBreakpoint('xl');
			if (width >= 1400) setBreakpoint('xxl');
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return breakpoint;
}

export default useWindowBreakpoint;
