import React, { useState, useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Input, LoadingIndicator } from '@/components';
import useAppContext from '@/contexts/AppContext';

import { useRedirection } from '@/hooks';
import { requestLogin } from '@/services/user';
import { LOCALSTORAGE_KEYS } from '@/utils/constants';

import lisaImage from '@/assets/images/lisa-login.png';
import * as S from './styles';

const Login: React.FC = () => {

	const navigate = useNavigate();
	const { saveUserData } = useAppContext();
	const { redirectionPath } = useRedirection({ fallback: '/' });

	const [ username, setUsername ] = useState<string>('');
	const [ password, setPassword ] = useState<string>('');
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	const [ isRememberMeChecked, setIsRememberMeChecked ] = useState<boolean>(false);

	const handleLogin = async () => {
		setIsLoading(true);
		toast.dismiss();

		try {
			const { token, usuario } = await requestLogin(username, password);

			if (isRememberMeChecked) {
				localStorage.setItem(LOCALSTORAGE_KEYS.DEFAULT_LOGIN_USERNAME, username);
			} else {
				localStorage.removeItem(LOCALSTORAGE_KEYS.DEFAULT_LOGIN_USERNAME);
			}

			saveUserData(usuario, token);
			navigate(redirectionPath);
		} catch (e) {
			const error: any = e;
			const defaultErrorMessage = 'Não foi possível realizar o login. Tente novamente!';
			
			if (!error.response) toast.error(defaultErrorMessage);
			toast.error(error.response.data.message);
		}

		setIsLoading(false);
	}

	useEffect(() => {
		const defaultUsername = localStorage.getItem(LOCALSTORAGE_KEYS.DEFAULT_LOGIN_USERNAME);
		
		if (defaultUsername) {
			setUsername(defaultUsername);
			setIsRememberMeChecked(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<S.Login isRememberMeChecked={ isRememberMeChecked }>
			{/* <div className="language-switch-container">
				<LanguageSwitch />
			</div> */}

			<img
				src={ lisaImage }
				alt="Lisa"
				className="lisa-image"
			/>

			<main className="login">
				<header className="login__header">
					<h1 className="login__title">
						Vialaser Coordenação
					</h1>

					<h3 className="login__subtitle">
						Entrar na plataforma
					</h3>
				</header>

				<div className="login__content">
					<Input
						id="username"
						type="text"
						label="Login"
						value={ username }
						onChangeValue={ setUsername }
					/>

					<Input
						id="password"
						type="password"
						label="Senha"
						value={ password }
						onChangeValue={ setPassword }
					/>

					<button
						className="remember"
						onClick={ () => setIsRememberMeChecked(!isRememberMeChecked) }
					>
						<div id="remember-me" className="remember__checkbox">
							<FiCheck className="remember__checkbox-icon" />
						</div>

						<label htmlFor="remember-me" className="remember__label">
							Lembrar de mim
						</label>
					</button>
				</div>

				<footer className="login__footer">
					<button
						className="login__button"
						disabled={ !username || !password || isLoading }
						onClick={ handleLogin }
					>
						{ isLoading ? <LoadingIndicator /> : 'Entrar na plataforma' }
					</button>
				</footer>
			</main>
		</S.Login>
	);

}

export default Login;
