import { FC } from 'react';
import * as S from './styles';

interface ModalProps {
	display?: S.IModal['display'];
	children: any;
}

const Modal: FC<ModalProps> = ({ display, children }) => (
	<S.Modal display={ display }>
		{ children }
	</S.Modal>
);

export default Modal;
