import React from 'react';

import useInspectionContext from '@/contexts/InspectionContext';
import * as S from './styles';

const Finish: React.FC = () => {

	const { selectedUnit, inspectionObservation, setInspectionObservation } = useInspectionContext();

	return (
		<S.Finish>
			<p className="finish__message">
				Visita realizada na clínica <strong>{ selectedUnit.descricao }</strong> em <strong>{ selectedUnit.cidade } - { selectedUnit.uf }</strong>. <br /><br /> Certifique-se que os itens marcados sejam aqueles que realmente identificam a natureza atual da clínica.
			</p>

			<div className="main__content-divider" />

			<div className="textarea">
				<label className="textarea__label" htmlFor="observation">
					Adicione uma observação <small>(opcional)</small>
				</label>

				<textarea
					id="observation"
					className="textarea__input"
					value={ inspectionObservation }
					onChange={ event => setInspectionObservation(event.target.value) }
				></textarea>
			</div>
		</S.Finish>
	);

}

export default Finish;
