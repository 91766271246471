import axios from 'axios';
import { STATUS_CODE, LOCALSTORAGE_KEYS } from '@/utils/constants';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		Authorization: process.env.REACT_APP_API_TOKEN!
	}
});

api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		const { status } = error.response;
		const { pathname } = window.location;
		
		const isUnauthorizedAndNotLogin = status === STATUS_CODE.UNAUTHORIZED && pathname !== '/login';
		if (isUnauthorizedAndNotLogin) {
			localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
			
			const { origin } = window.location;
			window.location.href = `${origin}/login`;

			alert('A sua sessão expirou. Faça login novamente!');
		}

		const isErrorResponseString = typeof error.response.data === 'string';
		if (isErrorResponseString) {
			error.response.data = {
				status: STATUS_CODE.INTERNAL_SERVER_ERROR,
				message: error.response.data
			}
		}
	
		return Promise.reject(error);
	}
);

// Invalid token for test: eyJhbGciOiJIUzI1NiJ9.eyJpZFVzdWFyaW8iOjEsImV4cCI6MTY1ODQzOTU5Nn0.8tZFTq4Mx4LXzhrCvx67mNUhXEdAy194dRJepXrRNq4
