import { api } from '@/services/api';
import { TInspectionGroupApiResponse, TAnswerForApi, TInspectionChartApiResponse, TIndicator } from '@/typings/api';

export const requestInspectionGroups = async () => {
	const { data: inspectionGroups } = await api.get<Array<TInspectionGroupApiResponse>>('/checklistAuditoriaUnidade/perguntas');
	return inspectionGroups;
}

export const requestInspectionChartData = async (
	unitId: number,
	isUserInUnit: boolean,
	answers: Array<TAnswerForApi>
) => {
	const { data: chartData } = await api.post<TInspectionChartApiResponse>('/checklistAuditoriaUnidade/resultado', {
		idUnidade: unitId,
		coordenadoraNaUnidade: isUserInUnit,
		respostas: answers
	});
	return chartData;
}

export const requestLastInspectionChartData = async (unitId: number) => {
	const { data: chartData } = await api.get<TInspectionChartApiResponse>(`/checklistAuditoriaUnidade/${unitId}/ultimoResultado`);
	return chartData;
}

export const requestSendInspectionAnswers = async (
	unitId: number,
	startDate: string,
	isUserInUnit: boolean,
	observation: string,
	answers: Array<TAnswerForApi>
): Promise<any> => {
	await api.post('/checklistAuditoriaUnidade/respostas', {
		idUnidade: unitId,
		dtHrInicio: startDate,
		coordenadoraNaUnidade: isUserInUnit,
		observacao: observation,
		respostas: answers
	});
}

export const requestGroupIndicators = async (pillarIndicator: string, unitId: number) => {
	const { data: indicatorsData } = await api.get<TIndicator>(`/checklistAuditoriaUnidade/indicadores/${pillarIndicator}/${unitId}`);
	return indicatorsData;
}
