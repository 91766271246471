import styled from 'styled-components';

export const SelectUnit = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	.title {
		font-size: ${props => props.theme.font.h3Size};
		font-weight: 600;
	}

	.units {
		display: flex;
		flex-direction: column;
		row-gap: .75rem;
	}

	.unit {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: transparent;

		&__data {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&__location {
			color: ${props => props.theme.colors.textLight};
			font-size: ${props => props.theme.font.smallSize};
			margin-top: .25rem;
		}
	}
`;
