import { TStickyNote } from '@/typings/notes';
import { makeId } from '@/utils/functions';

export const STICKY_NOTES_DATA: Array<TStickyNote> =  [
	{
		id: makeId(),
		title: '50',
		description: 'dias para feedback de Nayara',
		type: 'FEEDBACK'
	},
	{
		id: makeId(),
		title: '75',
		description: 'dias para feedback de Jéssica',
		type: 'FEEDBACK'
	},
	{
		id: makeId(),
		title: '100',
		description: 'dias para vistoria de Farol Shopping',
		type: 'INSPECTION'
	}
];
