import styled from 'styled-components';

export const NotFound = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #FFFFFF;

	.notfound {
		padding: 0 2rem;
		text-align: center;

		&__title {
			color: #141328;
			font-size: ${props => props.theme.font.h2Size};
			margin-bottom: ${props => props.theme.defaults.mb05};
		}

		&__message {
			color: #6e6d7a;
		}
	}
`;
