import styled, { css } from 'styled-components';

export const ErrorModal = styled.div<{ visible: boolean; }>`
	position: fixed;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 10;
	visibility: hidden;
	opacity: 0;
	transition: .3s;

	${props => props.visible && css`
		visibility: visible;
		opacity: 1;
	`};

	.errorbox {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		padding: 3rem;
		row-gap: 3rem;
		background-color: ${props => props.theme.colors.container};

		&__header {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			row-gap: 2.75rem;
		}

		&__title {
			font-size: ${props => props.theme.font.h1Size};
			margin-bottom: .25rem;
		}

		&__message {
			color: ${props => props.theme.colors.textLight};
		}

		&__button {
			height: 44px;
			font-weight: 500;
			border-radius: .75rem;
			background-color: ${props => props.theme.colors.red};
			border: 1px solid ${props => props.theme.colors.red};
			color: #fff;
		}
	}

	@media screen and (min-width: 767px) {
		.errorbox {
			width: 500px;
			height: auto;
			border-radius: 2rem;

			&__button {
				height: 52px;
			}
		}
	}

`;
