import React from 'react';
import Lottie from 'react-lottie';

import * as errorAnimation from '../../assets/animations/error.json';
import * as S from './styles';

interface ErrorModalProps {
	visible: boolean;
	title: string;
	message: string;
	onCloseModal: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ visible, title, message, onCloseModal }) => {
	return (
		<S.ErrorModal visible={ visible }>
			<div className="errorbox">
				<div className="errorbox__header">
					<Lottie
						width={ 796 / 3 }
						height={ 714 / 3 }
						isStopped={ false }
						isPaused={ false }
						options={{
							loop: true,
							autoplay: true, 
							animationData: errorAnimation,
							rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
						}}
					/>

					<div>
						<h1 className="errorbox__title">
							{ title }
						</h1>

						<p className="errorbox__message">
							{ message }
						</p>
					</div>				
				</div>
				
				<button className="errorbox__button" onClick={ onCloseModal }>
					Fechar
				</button>
			</div>
		</S.ErrorModal>
	);
}

export default ErrorModal;
