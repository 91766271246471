import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export interface IModal {
	display?: {
		direction: 'row' | 'column';
		items: 'flex-start' | 'center' | 'flex-end';
		content: 'flex-start' | 'center' | 'flex-end';
	}
}

export const Modal = styled.div<IModal>`
	position: fixed;
	inset: 0;
	background-color: ${props => transparentize(.8, props.theme.colors.body)};
	backdrop-filter: blur(3px);
	z-index: 10;
	transition: .3s;

	display: flex;
	align-items: center;
	justify-content: center;

	${props => props.display && css`
		flex-direction: ${props.display.direction};
		align-items: ${props.display.items};
		justify-content: ${props.display.content};
	`};

`;
