import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import { Home, Inspection, Feedback, Login, NotFound } from '@/pages';

import { InspectionProvider } from '@/contexts/InspectionContext';
import { FeedbackProvider } from '@/contexts/FeedbackContext';

import useAppContext from '@/contexts/AppContext';

const Private: React.FC<{ children: any }> = ({ children }) => {
	const { isAuthenticated } = useAppContext();
	if (!isAuthenticated) return <Navigate to="/login" replace />;
	return children;
}

const Routes: React.FC = () => {
	return (
		<SkeletonTheme baseColor='#ebebeb' highlightColor='#dddddd'>
			<Switch>
				<Route path="/" element={ 
					<Private>
						<Home />
					</Private> 
				} />

				<Route path="/login" element={ <Login /> } />

				<Route path="/inspection" element={
					<Private>
						<InspectionProvider>
							<Inspection />
						</InspectionProvider>
					</Private>
				} />

				<Route path="/feedback" element={ 
					<Private>
						<FeedbackProvider>
							<Feedback />
						</FeedbackProvider> 
					</Private>
				} />
				
				<Route path="*" element={ <NotFound /> } />
			</Switch>
		</SkeletonTheme>
	);
}

export default Routes;
