import React from 'react';
import * as S from './styles';

const SelectCollaborator: React.FC = () => {

	return (
		<S.SelectCollaborator>
			<h3 className="title">
				Quem você quer avaliar?
			</h3>

			<div className="collaborators">
				<button className="collaborator">
					<div className="collaborator__data">
						<p className="collaborator__name">
							Fernanda Gomes
						</p>

						<small className="collaborator__status">
							Líder
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator" />
						</div>
					</div>
				</button>

				<button className="collaborator">
					<div className="collaborator__data">
						<p className="collaborator__name">
							Leticia Alves
						</p>

						<small className="collaborator__status">
							Aplicadora
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator radio__indicator-selected" />
						</div>
					</div>
				</button>

				<button className="collaborator">
					<div className="collaborator__data">
						<p className="collaborator__name">
							Nayara Goulart
						</p>

						<small className="collaborator__status">
							Aplicadora
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator" />
						</div>
					</div>
				</button>

				<button className="collaborator">
					<div className="collaborator__data">
						<p className="collaborator__name">
							Jéssica Dom
						</p>

						<small className="collaborator__status">
							Aplicadora
						</small>
					</div>

					<div className="radio">
						<div className="radio__middle">
							<div className="radio__indicator" />
						</div>
					</div>
				</button>
			</div>
		</S.SelectCollaborator>
	);

}

export default SelectCollaborator;
