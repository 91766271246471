import React from 'react';
import { Oval } from 'react-loader-spinner';

interface LoadingIndicatorProps {
	width?: number;
	height?: number;
	stroke?: number;
	color?: string;
	secondaryColor?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	width = 20,
	height = 20,
	stroke = 5,
	color = '#fff',
	secondaryColor = 'transparent'
}) => {
	return (
		<Oval
			ariaLabel="loading-indicator"
			width={ width }
			height={ height }
			strokeWidth={ stroke }
			color={ color }
			secondaryColor={ secondaryColor }
		/>
	);
}

export default LoadingIndicator;
