import styled, { css } from 'styled-components';
import { lighten, transparentize } from 'polished';

import { TWizardStepButtonType } from '@/typings/wizard';

type TInspection = {
	withTwoButtons: boolean;
	progressBarPercentage: string;
}

export const Inspection = styled.div<TInspection>`
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
	height: 100vh;
	background-color: ${props => props.theme.darkMode ? props.theme.colors.body : props.theme.colors.secondary};

	.header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 2rem 2rem 0 2rem;

		&__title {
			color: #fff;
			margin-bottom: .25rem;
			font-size: ${props => props.theme.font.h1Size};
		}

		&__subtitle {
			color: #F2F2F2;
			font-size: ${props => props.theme.font.h3Size};
			font-weight: 500;
		}

		&__button {
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: .5rem;
			color: #fff;
			font-size: ${props => props.theme.font.iconSize};
			background-color: rgba(255, 255, 255, 0.3);
		}
	}

	.progress {
		position: relative;
		margin: 0 2rem;

		&__bar {
			height: 6px;
			width: 100%;
			overflow: hidden;
			border-radius: 1rem;
			background-color: rgba(255, 255, 255, 0.3);

			&-indicator {
				width: ${props => props.progressBarPercentage};
				height: 100%;
				border-radius: 1rem;
				background-color: ${props => props.theme.colors.primary};
				transition: .3s;
			}
		}

		.indicator {
			margin-top: .5rem;
			display: flex;
			justify-content: flex-end;
			width: calc(${props => props.progressBarPercentage} + 17px);
			transition: .3s;

			&__box {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			&__triangle {
				width: 10px;
				height: 10px;
				background-color: ${props => props.theme.colors.primary};
				transform: rotate(45deg);
				margin-bottom: -7px;
			}

			&__percentage {
				width: 2.5rem;
				height: 1.75rem;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: ${props => props.theme.colors.primary};
				z-index: 2;

				> span {
					line-height: auto;
					font-weight: 500;
					color: #fff;
					font-size: ${props => props.theme.font.smallerSize};
				}
			}
		}
	}

	.main {
		flex: 1;
		border-top-left-radius: 2rem;
		border-top-right-radius: 2rem;
		background-color: ${props => props.theme.colors.container};
		position: relative;
		display: flex;
		flex-direction: column;
		padding-bottom: 108px;

		.radio {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			border: 2px solid ${props => props.theme.colors.primary};
			background-color: ${props => props.theme.colors.container};

			&-selected {
				width: 12px;
				height: 12px;
				border-radius: 100%;
				background-color: ${props => props.theme.colors.primary};
				transition: .3s;
			}
		}
	}

	.content {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		row-gap: 2rem;
		position: relative;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__title {
			font-size: ${props => props.theme.font.h2Size};
		}

		&__button {
			background-color: transparent;
			color: ${props => props.theme.colors.primary}
		}
	}

	.footer {
		display: flex;
		align-items: center;
		justify-content: ${props => props.withTwoButtons ? 'space-between' : 'flex-end'};
		border-top: 1px solid ${props => props.theme.colors.body};
		background-color: ${props => props.theme.colors.container};
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 5;
		padding: 2rem;
		transition: .3s;
	}

	.cluster {
		top: -.65rem;
		right: 2.35rem;
		position: absolute;
		
		&__text {
			color: #fff;
			padding: .25rem .5rem;
			font-size: ${props => props.theme.font.smallSize};
			border-radius: 50px;
			
			&-green {
				background-color: ${props => props.theme.colors.primary};
				box-shadow: 0 0 5px ${props => transparentize(.25, props.theme.colors.primary)};
			}
			&-yellow {
				background-color: ${props => props.theme.colors.yellow};
				box-shadow: 0 0 5px ${props => transparentize(.25, props.theme.colors.yellow)};
			}
			&-red {
				background-color: ${props => props.theme.colors.red};
				box-shadow: 0 0 5px ${props => transparentize(.25, props.theme.colors.red)};
			}
		}
	}

	@media screen and (min-width: 767px) {
		min-height: 100vh;
		height: auto;

		.header {
			width: 650px;
			align-self: center;
		}
		
		.progress {
			width: 650px;
			align-self: center;
		}

		.main {
			width: 650px;
			align-self: center;
			border-bottom-left-radius: 2rem;
			border-bottom-right-radius: 2rem;
			margin-bottom: 2rem;
			padding-bottom: 0;
		}

		.footer {
			position: relative;
			border-bottom-left-radius: 2rem;
			border-bottom-right-radius: 2rem;
		}
	}
`;

export const StepAction = styled.button<{ btnType: TWizardStepButtonType; }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 52px;
	width: 116px;
	border-radius: .75rem;
	font-weight: 500;
	transition: .3s;

	${props => props.btnType === 'PREVIOUS' && css`
		color: ${props.theme.colors.text};
		background-color: ${props.theme.colors.body};
		border-color: ${props.theme.colors.body};
	`};

	${props => props.btnType === 'NEXT' && css`
		color: #fff;
		background-color: ${props.theme.colors.primary};
		border-color: ${props.theme.colors.primary};
	`};

	&:disabled {
		${props => props.btnType === 'NEXT' && css`
			color: #fff;
			background-color: ${lighten(.2, props.theme.colors.primary)};
			border-color: ${lighten(.2, props.theme.colors.primary)};
			cursor: no-drop;
		`};
	}

	@media screen and (min-width: 767px) {
		width: 132px;
	}
`;

export const CenteredContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 2rem;
	padding: 2rem;
	height: 100vh;
	background-color: ${props => props.theme.colors.container};

	.error {
		display: flex;
		flex-direction: column;
		row-gap: .5rem;
		text-align: center;

		&__title {
			color: ${props => props.theme.colors.red};
			font-size: ${props => props.theme.font.h1Size};
		}
	}

	.button {
		padding: 1rem 2rem;
		border-radius: 1rem;
		font-weight: 500;
		
		&-red {
			background-color: ${props => props.theme.colors.red};
			color: #fff;
		}
	}
`;
