import { api } from '@/services/api';
import { TLoginData, TUnit } from '@/typings/api';

export const requestLogin = async (username: string, password: string) => {
	const { data: loginData } =
		await api.post<TLoginData>(
			'/auth/login',
			{ username, password, origin: 'KPI' }
		);
		
	return loginData;
}

export const requestUserUnits = async () => {
	const { data: units } = await api.get<Array<TUnit>>('/checklistAuditoriaUnidade/unidades');
	return units;
}
