import { TCoordinate } from '@/typings/api';

export const makeId = () => {
	let id = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let index = 0; index < 7; index++) {
      id += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return id;
}

export const getWindowDimensions = () => {
	try {
		const { innerWidth: width, innerHeight: height } = window;
  		return { width, height };
	} catch (error) {
		return { width: 0, height: 0 };
	}
}

export const calculateProgressBarPercentage = (stepsLength: number, currentStep: number) => {
	const allSteps = stepsLength || 1;
	const percentage = (100 * (currentStep + 1)) / allSteps;

	return ~~percentage + '%';
}

export const calculateDistanceBetweenCoordsInKM = (coord1: TCoordinate, coord2: TCoordinate) => {
	const degreeToRadius = (degree: number) => {
		return degree * (Math.PI / 180);
	}

	const R = 6371;
	const dLat = degreeToRadius(coord2.lat - coord1.lat);
    const dLng = degreeToRadius(coord2.lng - coord1.lng);

	const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
	+ Math.cos(degreeToRadius(coord1.lat))
	* Math.cos(degreeToRadius(coord1.lat))
	* Math.sin(dLng / 2) * Math.sin(dLng / 2)

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c * 1000;
}

