import React from 'react';

import useFeedbackContext from '@/contexts/FeedbackContext';

import { TFeedbackQuestion } from '@/typings/feedback';
import * as S from './styles';

const Questions: React.FC<{ questions: Array<TFeedbackQuestion> }> = ({ questions }) => {

	const { changeQuestionAnswer } = useFeedbackContext();

	const isCurrentQuestionVisible = (question: TFeedbackQuestion) => {
		if (!question.fatherQuestion) return true;

		const { fatherQuestion } = question;
		const foundedFatherQuestion = questions.find(question => question.id === fatherQuestion.id);

		return foundedFatherQuestion?.answer === fatherQuestion.answer;
	}

	return (
		<S.Questions>
			{
				questions.map((question, index) => (
					<div
						key={ question.id }
						className={`question ${isCurrentQuestionVisible(question) && 'question-visible'}`}
					>
						<h3 className="question__title">
							{ question.question }
						</h3>

						{
							question.questionType === 'FIELD' ? (
								<input
									name="questionAnswer"
									placeholder="Insira sua resposta aqui"
									className="question__field"
									value={ question.answer }
									onChange={ event => changeQuestionAnswer(index, event.target.value) }
								/>
							) : (
								<div className="question__answers">
									{
										question.questionAnswers?.map(answer => (
											<button
												key={ answer.id }
												className="answer"
												onClick={ () => changeQuestionAnswer(index, answer.answer) }
											>
												<div className="radio">
													<div className="radio__middle">
														<div className={
															`radio__indicator ${question.answer === answer.answer && 'radio__indicator-selected'}`
														} />
													</div>
												</div>

												<p className="answer__description">
													{ answer.answer }
												</p>
											</button>
										))
									}
								</div>
							)
						}
					</div>
				))
			}
		</S.Questions>
	);

}

export default Questions;
