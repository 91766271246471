import { TAnswerForApi } from '@/typings/api';
import { TWizardStep } from '@/typings/wizard';

export const createAnswersArrayForApi = (steps: Array<TWizardStep>) => {
	const answers: Array<TAnswerForApi> = [];

	steps.filter(step => step.type === 'INSPECTION_GROUP').forEach(group => {
		group.inspectionSubgroups?.forEach(subgroup => {
			subgroup.perguntas.forEach(question => {
				if (question.respostaSelecionada) answers.push({
					idPerguntaChecklistAuditoriaUnidade: question.idPergunta,
					idOpcaoRespostaChecklistAuditoriaUnidade: question.respostaSelecionada
				});
			});
		});
	});

	return answers;
}
