import styled from 'styled-components';

export const LanguageSwitch = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: .5rem;
	padding: .25rem .5rem .25rem .25rem;
	border-radius: 50px;
	background-color: ${props => props.theme.colors.container};

	.language-switch-flag {
		width: 24px;
	}

	.language-switch-initials {
		font-weight: 500;
		font-size: ${props => props.theme.font.smallerSize};
	}
`;
