import styled from 'styled-components';
import { darken, transparentize } from 'polished';

export const Home = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 2rem;
	padding: 2rem;
	min-height: 100vh;
	background-color: ${props => props.theme.colors.container};
	transition: .3s;

	.logout {
		display: flex;
		align-items: center;
		column-gap: 1rem;
		background-color: transparent;
		color: ${props => props.theme.colors.textLight};

		&__icon {
			font-size: ${props => props.theme.font.iconSize};
			color: ${props => props.theme.colors.primary};
		}
	}

	.header {
		&__title {
			font-weight: 700;
			font-size: ${props => props.theme.font.h1Size};
		}

		&__subtitle {
			font-weight: 600;
			font-size: ${props => props.theme.font.h2Size};
			color: ${props => props.theme.colors.textLight};
		}
	}

	.nav {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		align-self: stretch;
		row-gap: 1.5rem;

		.link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			-webkit-tap-highlight-color: transparent;
			cursor: pointer;

			&__data {
				display: flex;
				align-items: center;
				column-gap: 1rem;
			}

			&__subdata {
				display: flex;
				flex-direction: column;
			}

			&__icon {
				width: 40px;
				height: 40px;
				border-radius: .75rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: ${props => props.theme.font.iconSize};

				&-green {
					color: ${props => props.theme.colors.primary};
					background-color: ${props => transparentize(.75, props.theme.colors.primary)};
				}

				&-purple {
					color: ${props => props.theme.colors.secondary};
					background-color: ${props => transparentize(.75, props.theme.colors.secondary)};
				}

				&-gray {
					color: ${props => props.theme.colors.textLight};
					background-color: ${props => transparentize(.75, props.theme.colors.textLight)};
				}
			}

			&__label {
				font-weight: 500;
				color: ${props => props.theme.colors.text};
			}

			&__sublabel {
				color: ${props => props.theme.colors.textLight};
				font-size: ${props => props.theme.font.smallerSize};
			}

			&__arrow {
				color: ${props => props.theme.colors.textLight};
				font-size: ${props => props.theme.font.iconSize};

				&-hidden {
					visibility: hidden;
				}
			}

			&-disabled {
				cursor: no-drop;

				.link__label {
					color: ${props => props.theme.colors.textLight};
				}
			}

			&:active {
				color: ${props => props.theme.colors.text};
			}
		}
	}

	.divider {
		height: 2px;
		border-radius: 10px;
		align-self: stretch;
		background-color: ${props => props.theme.colors.body};
	}

	.notes-title {
		font-weight: 600;
		font-size: ${props => props.theme.font.normalSize};
	}

	.notes {
		/* align-self: stretch;
		display: grid;
		gap: 1rem;
		grid-template-columns: repeat(2, 1fr); */

		display: flex;
		flex-direction: column;
		align-self: stretch;
		align-items: center;

		&__title {
			font-size: ${props => props.theme.font.h3Size};
		}

		.note {
			display: flex;
			flex-direction: column;
			row-gap: .25rem;
			padding: 1.5rem;
			border-radius: 1.25rem;
			background-color: ${props => props.theme.colors.secondary};
			color: #fff;
			height: auto;
			transition: .3s;

			&__title {
				font-size: ${props => props.theme.font.h1Size};
			}

			&-green {
				background-color: ${props => props.theme.colors.primary};

				&:hover {
					background-color: ${props => darken(.1, props.theme.colors.primary)};
				}
			}

			&-purple {
				background-color: ${props => props.theme.colors.secondary};

				&:hover {
					background-color: ${props => darken(.1, props.theme.colors.secondary)};
				}
			}

			&-last {
				grid-column: 1 / 3;
			}
		}
	}

	.footer {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-self: stretch;
		align-items: center;
		justify-content: flex-end;
		row-gap: .25rem;
		color: ${props => props.theme.colors.textLight};

		.copyright {
			font-size: ${props => props.theme.font.smallSize};
		}

		.version {
			font-size: ${props => props.theme.font.smallerSize};
		}
	}

	@media screen and (min-width: 1250px) {
		padding: 4rem 20rem;
	}
`;
